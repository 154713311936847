import React, { useState } from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box/Box'
import Grid from '@mui/material/Grid'
import styled from '@mui/material/styles/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles/createTheme'
import { ProducerKitsHeader } from 'components/ProducerKitsHeader'
import { Footer } from 'components/Footer'
import { ProducerKitsButton } from 'components/ProducerKitsButton'
import { ArrowDownIcon } from 'icons/ArrowDownIcon'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { MusicTrack_SS, Playlist_SS } from '@fivano/models'
import { landingPageType } from 'data/landingPageData'
import { SignInFirstModal } from 'components/SignInFirstModal'
import { ActivatePlanModal } from 'components/ActivatePlanModal'
import PlayArrow from '@mui/icons-material/PlayArrow'
import PlayCircle from '@mui/icons-material/PlayCircle'
import { PageSection, PageSectionWrapper } from 'components/PageSection'
import { PricingCards } from 'components/PricingCards'
import { PlaylistCard } from 'components/PlaylistCard'
import { PlaylistCardInnerTitle } from 'components/PlaylistCardInnerTitle'
import { SwitchToggle } from 'components/SwitchToggle'
import { Modal } from 'components/Modal'
import { AvaterGroupBlock } from 'components/AvatarGroupBlock'
import { fileToMediaURL } from 'utils/fileToMediaURL'
import { differenceInDays } from 'date-fns'
import { convertTimestampDate } from 'components/DatePicker'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import 'swiper/css/free-mode'
import { CheckIcon } from 'icons/CheckIcon'
import { playStateType, TracksList } from 'containers/Tracks/TracksList'
import { useAuth } from 'hooks/useAuth'
import { HowDoesItWorkIcon } from 'icons/HowDoesItWorkIcon'
import { LinkClick } from 'components/LinkClick'
import { optionType } from 'types/types'

const HeaderContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  height: 900,
  maxWidth: '100%',
  [theme.breakpoints.down('md')]: {
    height: 720,
  },
  [theme.breakpoints.down('sm')]: {
    height: '85vh',
    minHeight: 640,
  },
}))
const ContentOverlay = styled('div')(() => ({
  position: 'absolute',
  width: '100%',
  zIndex: 1,
}))
const BackgroundImage = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}))
const ArtistCard = styled('div')(() => ({
  position: 'relative',
  backgroundColor: 'rgba(247, 137, 84, 0.3)',
  border: '2px solid white',
  borderRadius: 10,
  overflow: 'hidden',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'rgba(247, 137, 84, 0.5)',
  },
}))
const SectionWrapper = styled(PageSectionWrapper)(() => ({
  borderTop: '4px solid #444',
  height: '800px',
}))

type HomePageTypes = {
  howDoesItWorkTrack: MusicTrack_SS
  genres: Playlist_SS[]
  tracks: MusicTrack_SS[]
  playlists: Playlist_SS[]
  landingPage?: landingPageType
  plans: any
  headerPlaylists: Playlist_SS[]
  searchPlaylists: Playlist_SS[]
  countries: optionType[]
}

type Video_SS = {
  image?: string | null
  artist?: string | null
  description?: string | null
  videoURL: string | null
  videoWidth: number
  videoHeight: number
}

const homeVideoData: Video_SS = {
  description: null, //'What is Producer Kits?',
  videoURL: null, //'PePOx8HJ-4A',
  videoWidth: 1120,
  videoHeight: 630,
}
const artistsData: Video_SS[] = [
  // {
  //   image: '/images/artists-desktop-karra.png',
  //   artist: 'KARRA',
  //   description: `Artist & Songwriter`,
  //   videoURL: '30Bbynh0XKs',
  //   videoWidth: 350,
  //   videoHeight: 620,
  // },
  // {
  //   image: '/images/artists-desktop-patrick-cc.png',
  //   artist: 'Patrick Cc',
  //   description: `Youtuber`,
  //   videoURL: 'OIXUHLWPFMQ',
  //   videoWidth: 1050,
  //   videoHeight: 600,
  // },
  // {
  //   image: '/images/artists-desktop-seids.png',
  //   artist: 'Seids',
  //   description: `Artist & Songwriter`,
  //   videoURL: 'caMN_txRl5o',
  //   videoWidth: 350,
  //   videoHeight: 620,
  // },
]

const SwiperStyledHeader = styled(Swiper)(({ theme }) => ({
  '.swiper-slide': {
    width: '160px',
    height: '160px',
    marginBottom: '20px',
    [theme.breakpoints.down('lg')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('md')]: {
      width: '96px',
      height: '96px',
    },
  },
  overflowX: 'hidden',
  /** Hide horizontal scrollbar */
  /* Chrome and other MODERN browsers */
  '::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  [theme.breakpoints.down('xl')]: {
    paddingRight: 0,
  },
  '.swiper-wrapper': {
    transitionTimingFunction: 'linear',
  },
}))
const SwiperStyledLarge = styled(Swiper)(({ theme }) => ({
  '.swiper-slide': {
    width: 340,
    height: 340,
    marginBottom: 20,
    [theme.breakpoints.down('lg')]: {
      width: 280,
      height: 280,
    },
    [theme.breakpoints.down('md')]: {
      width: 200,
      height: 200,
    },
  },
  overflowX: 'hidden',
  /** Hide horizontal scrollbar */
  /* Chrome and other MODERN browsers */
  '::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  [theme.breakpoints.down('xl')]: {
    paddingRight: 0,
  },
  '.swiper-wrapper': {
    transitionTimingFunction: 'linear',
  },
}))

export const HomePage = ({
  landingPage,
  plans,
  playlists,
  howDoesItWorkTrack,
  headerPlaylists,
  searchPlaylists,
  countries,
}: HomePageTypes) => {
  const { query, push, replace } = useRouter()
  const { t } = useTranslation('home')
  const [period, setPeriod] = useState<'month' | 'year'>('month')
  const [signInFirstModal, setSignInFirstModal] = useState(false)
  const [activePlanModal, setActivePlanModal] = useState(false)
  const [videoData, setVideoData] = useState<Video_SS | null>(null)
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { user } = useAuth()
  const [playState, setPlayState] = useState<playStateType>({
    musicTrackID: null,
    status: 'pauzed',
    samplePreviewID: '',
  })
  const [expandTrack, setExpandTrack] = useState<boolean>(false)

  return (
    <>
      <Head>
        <title>
          {landingPage?.name
            ? `${landingPage.name} - ${t<string>('named_home_title')}`
            : t<string>('default_home_title')}
        </title>
        <link rel='icon' href='/favicon.ico' />
        <style type='text/css'>{`html { scroll-behavior: smooth; }`}</style>
      </Head>
      {videoData && (
        <Modal
          customMaxWidth={videoData.videoWidth}
          open
          onClose={() => setVideoData(null)}
          // @ts-ignore
          sx={{ root: { borderRadius: '10px', overflow: 'hidden' } }}
        >
          <Box
            sx={{
              position: 'relative',
              maxWidth: '100%',
              paddingTop: `calc(${videoData.videoHeight} / ${videoData.videoWidth} * 100%)`,
              height: '0px',
              overflow: 'hidden',
              iframe: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              },
            }}
          >
            <iframe
              width={`${videoData.videoWidth}`}
              height={`${videoData.videoHeight}`}
              src={`https://www.youtube.com/embed/${videoData.videoURL}?&showinfo=0&rel=0&loop=1&autoplay=1&controls=0&loop=1`}
              title='YouTube video player'
              // @ts-ignore
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </Box>
        </Modal>
      )}
      <ProducerKitsHeader disableTopSpacing countries={countries} />
      {signInFirstModal && (
        <SignInFirstModal onClose={() => setSignInFirstModal(false)} />
      )}
      {activePlanModal && (
        <ActivatePlanModal onClose={() => setActivePlanModal(false)} />
      )}

      {/** PAGE HEADER */}
      <HeaderContainer id='home' maxWidth={false} disableGutters>
        <ContentOverlay
          sx={{
            padding: '0px 0px',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <Container maxWidth='xl'>
            <Typography
              variant='h1'
              align={downSM ? 'center' : 'left'}
              sx={{
                whiteSpace: 'pre-line',
                fontWeight: 700,
                marginBottom: '16px',
                marginTop: downMD ? '40px' : '80px',
              }}
            >
              Samples That Work
            </Typography>
            <Typography
              variant='subtitle1'
              align={downSM ? 'center' : 'left'}
              sx={{ whiteSpace: 'pre-line', marginBottom: '24px' }}
              gutterBottom
            >
              Find the pro sound you need easier than ever in our artist kits
              catalog
            </Typography>

            <Grid
              container
              spacing={2}
              justifyContent={downSM ? 'center' : 'flex-start'}
            >
              <Grid item>
                <ProducerKitsButton
                  variant='contained'
                  onClick={() => push('/explore')}
                >
                  Explore
                </ProducerKitsButton>
              </Grid>
              {homeVideoData.videoURL && (
                <Grid item>
                  <ProducerKitsButton
                    variant='outlined'
                    startIcon={<PlayCircle />}
                    onClick={() => setVideoData(homeVideoData)}
                    sx={{
                      '.MuiButton-startIcon': {
                        scale: 1.5,
                        marginLeft: '-12px',
                        marginRight: '12px',
                      },
                    }}
                  >
                    {homeVideoData.description}
                  </ProducerKitsButton>
                </Grid>
              )}
              <Grid item xs={12} />
            </Grid>
          </Container>
          <Grid
            container
            sx={{
              background: 'rgba(0,0,0,0.3)',
              marginTop: '32px',
              padding: '12px 0px',
            }}
          >
            <SwiperStyledHeader
              slidesPerView={'auto'}
              updateOnWindowResize
              modules={[Autoplay]}
              spaceBetween={16}
              slidesPerGroup={1}
              loop
              loopFillGroupWithBlank
              speed={4000}
              autoplay={{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
            >
              {headerPlaylists.map(item => (
                <SwiperSlide key={item.idPlaylist}>
                  <PlaylistCard
                    src={fileToMediaURL(item.playlistImages?.[0])}
                    loadImagePriority
                    path={`/explore/playlists/${item.slug}`}
                    marginTopImage={10}
                    tag={
                      differenceInDays(
                        new Date(),
                        convertTimestampDate(
                          item.publishedAt === null ? 0 : item.publishedAt,
                        ),
                      ) < 14
                        ? 'New'
                        : ''
                    }
                    imageSizes={{
                      md: 96,
                      lg: 120,
                      default: 160,
                    }}
                  />
                </SwiperSlide>
              ))}
            </SwiperStyledHeader>
          </Grid>
        </ContentOverlay>
        <Grid
          container
          justifyContent='center'
          position='absolute'
          zIndex={1}
          bottom='5%'
        >
          <Grid item>
            <AvaterGroupBlock />
          </Grid>
        </Grid>
        <BackgroundImage
          sx={{
            height: '100%',
            img: {
              opacity: 0.4,
            },
          }}
        >
          <Image
            alt='background'
            layout='fill'
            objectFit='cover'
            src='/producerkits-royalty-free-music-on-subscription-homepage-background.jpg'
            priority
          />
        </BackgroundImage>
      </HeaderContainer>
      <Box
        position='absolute'
        zIndex={3}
        marginTop='-16px'
        left='calc(50% - 25px)'
      >
        <ArrowDownIcon />
      </Box>
      <PageSection
        maxWidth='xl'
        title='How Does It Work?'
        subtitle='Let us explain how you can get started.'
      >
        <Grid container spacing={2}>
          {[
            {
              title: 'Find The Sound You Need',
              description: `Explore our artist kits catalog and find exactly what you need by selecting a playlist.`,
            },
            {
              title: 'Listen In Total & Unfold Kit',
              description: `Listen kits in total with the full track, unfold the kit to hear all separate samples.`,
            },
            {
              title: 'Download The Sample',
              description: `Download the individual samples you like for 1 credit per download.`,
            },
            {
              title: 'Instant Use & Release',
              description: `Use and release it instantly with the pro sound and royalty-free license!`,
            },
          ].map((item, index) => (
            <Grid key={item.title} item md={6} lg={3} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container alignItems='center'>
                    <Grid item minWidth={downSM ? '20px' : '30px'}>
                      <Typography
                        variant='h3'
                        color='primary'
                        gutterBottom
                        align='right'
                        paddingRight='10px'
                        fontWeight='bold'
                      >
                        {index + 1}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant='h5' fontWeight={'bold'} gutterBottom>
                        {item.title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item minWidth={downSM ? '0' : '30px'} />
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant='subtitle1'
                    color='textSecondary'
                    lineHeight={1.4}
                  >
                    {item.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}

          {howDoesItWorkTrack?.idTrack && (
            <>
              <Grid item xs={12}>
                <br />
                <br />
                <Grid container alignItems='center'>
                  <Typography gutterBottom variant='h5' color='primairy'>
                    {howDoesItWorkTrack.nameTrack}
                  </Typography>
                  <LinkClick
                    gutterBottom
                    variant='subtitle2'
                    color='textSecondary'
                    paddingLeft={2}
                    onClick={() => {
                      const playlistID =
                        howDoesItWorkTrack?.playlists[0]?.playlist?.value
                      if (!playlistID) return
                      const playlist = playlists.find(
                        item => item.idPlaylist === playlistID,
                      )
                      if (!playlist) return
                      push(`/explore/playlists/${playlist.slug}`, undefined, {
                        scroll: false,
                      })
                    }}
                  >
                    from {howDoesItWorkTrack.playlists[0]?.playlist.label}
                  </LinkClick>
                </Grid>
                <TracksList
                  tracks={[howDoesItWorkTrack]}
                  tracksLimit={1}
                  currentUser={user}
                  playState={playState}
                  onPlayStateChange={setPlayState}
                  onExpandTrack={setExpandTrack}
                  disableShowMore
                />
                {!expandTrack && !downSM && (
                  <Grid container marginTop={3} position='relative'>
                    <Grid
                      item
                      sx={{
                        position: 'absolute',
                        left: '2%',
                        top: '-40px',
                      }}
                    >
                      <HowDoesItWorkIcon />
                      <Typography
                        variant='subtitle2'
                        sx={{
                          position: 'absolute',
                          left: '55px',
                          top: '45px',
                          width: '600px',
                        }}
                      >
                        Unfold, hear and download the separate samples
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )}

          <Grid item xs={12} marginTop='32px'>
            <Grid container justifyContent='center'>
              <ProducerKitsButton
                variant='contained'
                onClick={() => push('/explore')}
              >
                Explore
              </ProducerKitsButton>
            </Grid>
          </Grid>
        </Grid>
      </PageSection>
      <SectionWrapper>
        <Container maxWidth={'xl'}>
          <Grid item xs={12}>
            <Typography
              variant='h2'
              sx={theme => ({
                whiteSpace: 'pre-line',
                marginBottom: '16px',
                fontSize: 44,
                color: '#fff',
                [theme.breakpoints.down('lg')]: {
                  fontSize: 40,
                },
                [theme.breakpoints.down('md')]: {
                  fontSize: 36,
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: 32,
                },
              })}
            >
              {`No More Endlessly Searching`}
            </Typography>
            <Typography
              maxWidth={'1200px'}
              variant='subtitle1'
              color='textSecondary'
            >
              {`With our artist-type playlists you can easily find what you need.`}
            </Typography>
          </Grid>
          <Grid container spacing={2} marginTop='24px'>
            {[
              {
                title: 'Made By Multi-Platinum Producers',
              },
              {
                title: 'Hear The Samples In A Full Track',
              },
              {
                title: 'Work Efficiently With Pro Sounds',
              },
              {
                title: '100% Royalty-Free',
              },
            ].map(item => (
              <Grid key={item.title} item md={6} lg={3} xs={12}>
                <Grid container alignItems='center'>
                  <Grid item height='24px' padding='0px 8px 0px 0px'>
                    <CheckIcon style={{ fontSize: '32px' }} />
                  </Grid>
                  <Grid item xs>
                    <Typography variant='subtitle2'>{item.title}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container maxWidth={false} disableGutters>
          <Grid container marginTop='32px'>
            <SwiperStyledLarge
              slidesPerView={'auto'}
              updateOnWindowResize
              modules={[Autoplay]}
              spaceBetween={downMD ? 16 : 32}
              slidesPerGroup={1}
              loop
              loopFillGroupWithBlank
              speed={4000}
              autoplay={{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
            >
              {searchPlaylists.map(item => (
                <SwiperSlide key={item.idPlaylist}>
                  <PlaylistCardInnerTitle
                    loadImagePriority
                    src={fileToMediaURL(item.playlistImages?.[0])}
                    title={item.namePlaylist}
                    subtitle={item.genre?.label}
                    path={`/explore/playlists/${item.slug}`}
                    tagPosition='center'
                    tag={
                      differenceInDays(
                        new Date(),
                        convertTimestampDate(
                          item.publishedAt === null ? 0 : item.publishedAt,
                        ),
                      ) < 14
                        ? 'New'
                        : ''
                    }
                    imageSizes={{
                      md: 200,
                      lg: 280,
                      default: 340,
                    }}
                  />
                </SwiperSlide>
              ))}
            </SwiperStyledLarge>
          </Grid>
        </Container>
        <Container maxWidth={'xl'}>
          <Grid item xs={12} marginTop='32px'>
            <Grid container justifyContent='center'>
              <ProducerKitsButton
                variant='contained'
                onClick={() => push('/explore')}
              >
                Explore
              </ProducerKitsButton>
            </Grid>
          </Grid>
        </Container>
      </SectionWrapper>
      {artistsData.length > 0 && (
        <PageSection
          maxWidth='xl'
          title={`Approved By Top Producers & Artists`}
          subtitle={`We're proudly recommended by top producers & artists around the globe.`}
        >
          <Grid container>
            <Grid item xs={12}>
              {downMD && (
                <Box
                  position='relative'
                  height='94vw'
                  width='100vw'
                  marginLeft='-16px'
                  overflow='hidden'
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      height: '94vw',
                      left: '-30vw',
                      right: '-30vw',
                      width: '160vw',
                      '.swiper-slide': {
                        marginTop: '80px',
                      },
                      '.swiper-slide-active': {
                        height: '120vw',
                        transform: 'scale(1.4) translate(0px, 8%)',
                        transition: 'transform 0.3s ease-out',
                      },
                    }}
                  >
                    <Swiper
                      modules={[Pagination, Navigation]}
                      lazy={{
                        loadPrevNext: true,
                        loadPrevNextAmount: 3, //or, if you wish, preload the next 2 images
                      }}
                      slidesPerView={3}
                      centeredSlides
                      watchSlidesProgress
                      loop
                    >
                      {artistsData.map(item => (
                        <SwiperSlide
                          key={item.artist}
                          onClick={() => setVideoData(item)}
                        >
                          <Grid container justifyContent='center'>
                            <ArtistCard
                              sx={{
                                width: '40vw',
                              }}
                              onClick={() => setVideoData(item)}
                            >
                              <ContentOverlay
                                sx={{
                                  height: '100%',
                                }}
                              >
                                <Grid
                                  container
                                  alignItems='center'
                                  justifyContent='center'
                                  height='100%'
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      justifyContent='center'
                                      marginBottom={2}
                                    >
                                      <Grid item xs={12}>
                                        <Typography
                                          variant='h3'
                                          fontWeight='bold'
                                          gutterBottom
                                          paddingTop='40%'
                                          align='center'
                                        >
                                          {item.artist}
                                        </Typography>
                                        <Typography align='center'>
                                          {item.description}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <ProducerKitsButton
                                          startIcon={<PlayArrow />}
                                          onClick={() => setVideoData(item)}
                                          size='small'
                                          white
                                          style={
                                            downSM
                                              ? { width: '90px', height: '80%' }
                                              : { width: '90px' }
                                          }
                                        >
                                          PLAY
                                        </ProducerKitsButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ContentOverlay>

                              <BackgroundImage
                                sx={{
                                  paddingTop: 'calc(5 / 3.5 * 100%)',
                                }}
                              >
                                <Image
                                  style={{ opacity: 0.3 }}
                                  src={item?.image ? item.image : ''}
                                  layout='fill'
                                  objectFit='cover'
                                />
                              </BackgroundImage>
                            </ArtistCard>
                          </Grid>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                </Box>
              )}

              {!downMD && (
                <Grid container spacing={4} marginTop='32px'>
                  {artistsData.map(item => (
                    <Grid item md={4} xs={12} key={item.artist}>
                      <ArtistCard onClick={() => setVideoData(item)}>
                        <ContentOverlay
                          sx={{
                            height: '100%',
                          }}
                        >
                          <Grid
                            container
                            alignItems='center'
                            justifyContent='center'
                            height='100%'
                          >
                            <Grid item>
                              <Typography
                                align='center'
                                variant='h3'
                                fontWeight='bold'
                                gutterBottom
                              >
                                {item.artist}
                              </Typography>
                              <Typography variant='subtitle2' align='center'>
                                {item.description}
                              </Typography>
                              <Grid
                                container
                                justifyContent='center'
                                marginTop={2}
                              >
                                <ProducerKitsButton
                                  startIcon={<PlayArrow />}
                                  onClick={() => setVideoData(item)}
                                  size='small'
                                  white
                                  style={{ width: '90px' }}
                                >
                                  PLAY
                                </ProducerKitsButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ContentOverlay>

                        <BackgroundImage
                          sx={{
                            paddingTop: 'calc(450 / 520 * 100%)',
                          }}
                        >
                          <Image
                            style={{ opacity: 0.3 }}
                            src={item?.image ? item.image : ''}
                            layout='fill'
                            objectFit='cover'
                          />
                        </BackgroundImage>
                      </ArtistCard>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} marginTop='32px'>
              <Grid container justifyContent='center'>
                <ProducerKitsButton
                  variant='contained'
                  onClick={() => push('/explore')}
                >
                  Explore
                </ProducerKitsButton>
              </Grid>
            </Grid>
          </Grid>
        </PageSection>
      )}
      <PageSection
        maxWidth='xl'
        title='Pick Your Plan'
        subtitle={`Join over 3,000+ music producers and subscribe to get access to every sample in our catalog.`}
      >
        <Grid container justifyContent='center'>
          <Grid item marginTop='32px' marginBottom='24px'>
            <SwitchToggle
              value={period === 'year'}
              onChange={() => setPeriod(period === 'year' ? 'month' : 'year')}
              labelLeft='Bill Monthly'
              labelRight='Bill Annually'
            />
          </Grid>
        </Grid>
        <PricingCards
          plans={plans}
          period={period}
          onSelect={(plan, period) =>
            replace(
              {
                query: {
                  ...query,
                  subscribe: 'signup',
                  plan: plan,
                  period: period,
                },
              },
              undefined,
              { scroll: false },
            )
          }
        />
      </PageSection>
      {!user?.uid && (
        <>
          <ContentOverlay>
            <Box
              sx={{ borderTop: '4px solid #444', width: '100%', height: '4px' }}
            />
            <Container>
              <Grid container justifyContent='center' position='relative'>
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    align='center'
                    marginBottom='16px'
                    marginTop='48px'
                  >
                    Sign Up Now
                  </Typography>
                  <Typography
                    align='center'
                    marginBottom='24px'
                    variant='subtitle2'
                  >
                    Seems like you've liked it so far? Sign up and start
                    downloading {!downSM && <br />}
                    samples thar really work for creating your next hit song.
                  </Typography>
                </Grid>
                <ProducerKitsButton
                  variant='contained'
                  onClick={() =>
                    replace(
                      {
                        query: {
                          ...query,
                          subscribe:
                            query.plan && query.period ? 'signup' : 'plans',
                        },
                      },
                      undefined,
                      { scroll: false },
                    )
                  }
                >
                  Sign Up
                </ProducerKitsButton>
              </Grid>
            </Container>
          </ContentOverlay>
          <BackgroundImage
            sx={{
              height: '300px',
              opacity: '0.3',
            }}
          >
            <Image
              src='/images/homepage-footer.jpg'
              layout='fill'
              objectFit='cover'
            />
          </BackgroundImage>
        </>
      )}
      <Footer />
    </>
  )
}
