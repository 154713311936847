export const HowDoesItWorkIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='59.01'
    height='67.037'
    viewBox='0 0 59.01 67.037'
  >
    <g transform='translate(56.379 1.099) rotate(90)'>
      <path
        d='M.006,1.662c-.473.268,25.421-15.4,41.815,44.365'
        transform='translate(63.431 13.443) rotate(106.982)'
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeWidth='4'
      />
      <path
        d='M7.024,5.976l5.105,8.832L0,4.336,14.179,0Z'
        transform='matrix(0.995, -0.105, 0.105, 0.995, 0, 34.24)'
        fill='#fff'
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </svg>
)
