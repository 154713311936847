export const ArrowDownIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='51.182'
    height='28.79'
    viewBox='0 0 51.182 28.79'
  >
    <defs>
      <linearGradient
        id='linear-gradient'
        x1='0.5'
        x2='0.5'
        y2='1'
        gradientUnits='objectBoundingBox'
      >
        <stop offset='0' stopColor='#f68b54' />
        <stop offset='1' stopColor='#fd5769' />
      </linearGradient>
    </defs>
    <g id='caret-down' transform='translate(0 -63.953)'>
      <path
        id='Path_6'
        data-name='Path 6'
        d='M50.232,64.9a3.073,3.073,0,0,0-2.249-.95H3.2a3.074,3.074,0,0,0-2.25.95,3.139,3.139,0,0,0,0,4.5L23.341,91.793a3.137,3.137,0,0,0,4.5,0L50.232,69.4a3.138,3.138,0,0,0,0-4.5Z'
        transform='translate(0 0)'
        fill='url(#linear-gradient)'
      />
    </g>
  </svg>
)
