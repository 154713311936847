import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Breakpoint } from '@mui/material/styles'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

type PageSectionProps = {
  maxWidth?: Breakpoint | false
  hideBorderTop?: boolean
  title?: string
  subtitle?: string
  children: ReactNode | ReactNode[]
}
export const PageSectionWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'hideBorderTop',
})<{ hideBorderTop?: boolean }>(({ hideBorderTop }) => ({
  padding: '64px 0px 40px 0px',
  ...(!hideBorderTop && {
    borderTop: '4px solid #444',
  }),
  backgroundImage:
    'linear-gradient(0deg, rgba(34, 28, 25, 1) 0%, rgba(22, 26, 26, 1)  100%)',
  minHeight: '800px',
}))

export const PageSection = ({
  maxWidth = 'lg',
  hideBorderTop,
  title,
  subtitle,
  children,
}: PageSectionProps) => {
  return (
    <PageSectionWrapper hideBorderTop={hideBorderTop}>
      <Container maxWidth={maxWidth} sx={{}}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant='h2'
              sx={theme => ({
                whiteSpace: 'pre-line',
                marginBottom: '16px',
                fontSize: 44,
                color: '#fff',
                [theme.breakpoints.down('lg')]: {
                  fontSize: 40,
                },
                [theme.breakpoints.down('md')]: {
                  fontSize: 36,
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: 32,
                },
              })}
            >
              {title}
            </Typography>
            <Typography
              maxWidth={'1200px'}
              paddingBottom='32px'
              variant='subtitle1'
              color='textSecondary'
            >
              {subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </PageSectionWrapper>
  )
}
