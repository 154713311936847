import AvatarGroup from '@mui/material/AvatarGroup'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles/createTheme'
import Image from 'next/image'

export const AvaterGroupBlock = () => {
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <AvatarGroup
        total={7}
        max={10}
        spacing={22}
        sx={{
          justifyContent: 'center',
          '.MuiAvatar-root': {
            borderColor: 'white',
            xs: {
              width: 34,
              height: 34,
              fontSize: 15,
              borderColor: 'white',
            },
          },
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7].map(avatar => (
          <Avatar key={avatar}>
            <Image
              src={`/avatars/avatars${avatar}.png`}
              layout='fill'
              alt={`avatar${avatar}`}
              sizes={'34px'}
            />
          </Avatar>
        ))}
      </AvatarGroup>
      <Typography
        align='center'
        sx={{
          fontWeight: 'bold',
          marginTop: 1,
          fontSize: downSM ? 14 : 'unset',
        }}
        gutterBottom
      >
        Trusted by over 3,000+ producers
      </Typography>
    </>
  )
}
