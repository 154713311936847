import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function LockIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d='M12 17.5a2 2 0 0 0 2-2 2 2 0 0 0-2-2 2 2 0 0 0-2 2 2 2 0 0 0 2 2m6-9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-10a2 2 0 0 1 2-2h1v-2a5 5 0 0 1 5-5 5 5 0 0 1 5 5v2h1m-6-5a3 3 0 0 0-3 3v2h6v-2a3 3 0 0 0-3-3z' />
      <path d='M8.5 11.75h7.75v7.5H8.5z' />
    </SvgIcon>
  )
}
