import React from 'react'
import Image from 'next/image'
import Typography from '@mui/material/Typography'
import styled from '@mui/material/styles/styled'
import ButtonBase from '@mui/material/ButtonBase'
import { LinkTo } from './LinkTo'
import LockIcon from 'icons/LockIcon'
import { useRouter } from 'next/router'
import { MuiImageSizeRendering } from 'types/types'
import useTheme from '@mui/material/styles/useTheme'
import { ImageSizeSettingWidth } from './ImageSizeSetting'
type TagPosition = 'center' | 'left' | 'right'

const ButtonBaseImage = styled(ButtonBase)(() => ({
  position: 'relative',
  marginTop: '12px',
  width: '100%',
  height: '100%',
  opacity: 1,
  img: { borderRadius: 10, overflow: 'hidden' },
  borderRadius: 10,
  '> span': {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    transition: 'background-color 0.3s cubic-bezier(0, 0, 0, 1) 0s',
    borderRadius: 10,
  },
  ':hover': {
    cursor: 'pointer',
  },
  ':hover span': {
    borderRadius: 10,
    backgroundColor: 'rgba(0,0,0,0.2)',
    backgroundImage:
      'linear-gradient(90deg, rgba(252,92,102,0.1) 0%, rgba(247,133,85,0.1) 100%)',
  },
}))
const PlaylistTitle = styled(Typography)(() => ({
  padding: '8px',
  fontSize: '28px',
  fontWeight: 'bold',
  position: 'absolute',
  bottom: 50,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
const PlaylistSubtitle = styled(Typography)(() => ({
  padding: '8px',
  fontSize: '18px',
  position: 'absolute',
  bottom: 25,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
const LockOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  borderRadius: 10,
  backgroundColor: 'rgba(0,0,0,0.66)',
  '> svg': {
    width: 48,
    height: 'auto',
    color: theme.palette.text.primary,
    position: 'absolute',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      width: 36,
    },
  },
}))
const Tag = styled('div', {
  shouldForwardProp: prop => prop !== 'tagPosition' && prop !== 'tagLeft',
})<{ tagPosition?: TagPosition }>(({ theme, tagPosition }) => ({
  ...theme.typography.caption,
  color: '#fff',
  fontWeight: 'bold',
  padding: '1px 10px 0px 10px',
  position: 'absolute',
  backgroundImage:
    'linear-gradient(90deg, rgba(252,92,102,1) 0%, rgba(247,133,85,1) 100%)',
  ...(tagPosition === 'right' && {
    right: -15,
  }),
  ...(tagPosition === 'left' && {
    left: -15,
  }),
  top: -12,
  borderRadius: 16,
  zIndex: 50,
}))

type PlaylistCardTypes = {
  src: string
  title?: string
  subtitle?: string
  locked?: boolean
  height?: number
  tag?: string
  tagPosition?: TagPosition
  path: string
  onLocked?: (value?: any) => void
  loadImagePriority?: boolean
  imageSizes: MuiImageSizeRendering
}

export const PlaylistCardInnerTitle = ({
  src,
  title,
  subtitle,
  locked,
  path,
  tag,
  tagPosition = 'center',
  loadImagePriority = false,
  imageSizes,
  onLocked,
}: PlaylistCardTypes) => {
  const { push } = useRouter()
  const theme = useTheme()
  return (
    <LinkTo disabled={locked} href={path}>
      <ButtonBaseImage
        onClick={() => (locked ? onLocked && onLocked() : push(path))}
      >
        {tag && <Tag tagPosition={tagPosition}>{tag}</Tag>}
        <span />
        {locked && (
          <LockOverlay>
            <LockIcon />
          </LockOverlay>
        )}
        <PlaylistTitle textAlign={'center'}>{title}</PlaylistTitle>
        {subtitle && (
          <PlaylistSubtitle textAlign={'center'} sx={{ opacity: 0.9 }}>
            {subtitle}
          </PlaylistSubtitle>
        )}
        <Image
          priority={loadImagePriority}
          sizes={ImageSizeSettingWidth(imageSizes, theme)}
          layout='fill'
          placeholder='blur'
          blurDataURL='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOM/g8AAbsBXM7nFtsAAAAASUVORK5CYII='
          alt={
            (title && subtitle && `${title} - ${subtitle}`) ||
            (title && `${title}`)
          }
          src={src}
        />
      </ButtonBaseImage>
    </LinkTo>
  )
}
