import { getGenrePlaylists } from 'data/getGenrePlaylists'
import { firebaseObject } from 'containers/firebaseObject'
import { refreshRateSSR } from 'data/globalsVariables'
import { findTrackPlaylist } from 'utils/findTrackPlaylist'
import { HomePage } from 'containers/HomePage'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from 'firebase/firestore'
import { MusicTrack_SS, Playlist_SS } from '@fivano/models'
import { flattedFirestore } from 'utils/flattedFirestore'
import { getPlaylists } from 'data/getPlaylists'
import { getHomePagelayoutSettings } from 'data/getHomePageLayoutSettings'
import { getSortedCountries } from 'data/getSortedCountries'

export const getStaticProps = async ({ locale }: any) => {
  const db = getFirestore(firebaseObject.fb)
  const countries = await getSortedCountries()
  const pageLayout = await getHomePagelayoutSettings
  const genres = await getGenrePlaylists
  const playlists = await getPlaylists
  const tracks: MusicTrack_SS[] = []
  const trackFetch = await getDocs(
    query(
      collection(db, 'musicTracks'),
      where('playlistIDs', 'array-contains', 'qT3hh3DXmGZycp7QFNCS'),
    ),
  )
  trackFetch.forEach(doc => {
    tracks.push(flattedFirestore(doc.data()))
  })

  const genresSorted = genres.sort(
    /** quality: 1 is highest quality 1000 is lower */
    (a, b) => parseInt(a.ranking) - parseInt(b.ranking),
  )
  const playlistQuery: Playlist_SS[] = []
  const queryPlaylists = query(
    collection(db, 'playlists'),
    where('idPlaylist', '==', 'qT3hh3DXmGZycp7QFNCS'),
  )
  const querySnapshotPlaylists = await getDocs(queryPlaylists)
  querySnapshotPlaylists.forEach(doc => {
    playlistQuery.push(flattedFirestore(doc.data()))
  })

  const playlist = playlistQuery[0]
  const trackSorted = tracks.sort(
    (a, b) =>
      /** quality: 1 is highest quality 1000 is lower */
      findTrackPlaylist(a, playlist)?.quality -
      findTrackPlaylist(b, playlist)?.quality,
  )
  const plans = await getDoc(doc(firebaseObject.db, 'plans', 'plansData')).then(
    response => flattedFirestore(response.data()),
  )
  const playlistsSorted = playlists.sort(
    /** quality: 1 is highest quality 1000 is lower */
    (a, b) => parseInt(a.ranking) - parseInt(b.ranking),
  )

  const howDoesItWorkMusicTrack = pageLayout?.howDoesItWorkTrack
    ? await getDoc(
        doc(
          firebaseObject.db,
          'musicTracks',
          pageLayout?.howDoesItWorkTrack?.value,
        ),
      ).then(response => flattedFirestore(response.data()))
    : null
  const headerPlaylists: Playlist_SS[] = []
  if (pageLayout?.headerPlaylists) {
    pageLayout.headerPlaylists.forEach(playlist => {
      const playlistItem = playlistsSorted.find(
        item => item.idPlaylist === playlist.value,
      )
      if (playlistItem) headerPlaylists.push(playlistItem)
    })
  }
  const searchPlaylists: Playlist_SS[] = []
  if (pageLayout?.searchPlaylists) {
    pageLayout.searchPlaylists.forEach(playlist => {
      const playlistItem = playlistsSorted.find(
        item => item.idPlaylist === playlist.value,
      )
      if (playlistItem) searchPlaylists.push(playlistItem)
    })
  }

  return {
    props: {
      countries,
      genres: genresSorted,
      tracks: trackSorted,
      playlists: playlistsSorted,
      headerPlaylists,
      searchPlaylists,
      howDoesItWorkTrack: howDoesItWorkMusicTrack,
      plans,
      ...(await serverSideTranslations(locale, [
        'account',
        'home',
        'header',
        'common',
        'modals',
        'tracks',
        'plans',
      ])),
    },
    /** update static page every day (check is done when page is loaded) */
    revalidate: refreshRateSSR,
  }
}

export default HomePage
